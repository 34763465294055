.account__header__fields dl {
  margin-bottom: 0;
}

.column-header__wrapper {
  h1.column-header {
    margin: 0;
  }
}

textarea.autosuggest-textarea__textarea,
input.search__input,
input.spoiler-input__input {
  font-family: mastodon-font-sans-serif, sans-serif;
  font-weight: 400;
}

div.public-layout {
  div.public-account-header {
    div.public-account-header__bar {
      &::before {
        z-index: 0;
      }

      a.avatar {
        z-index: 1;
      }
    }
  }
}

body.admin {
  background: var(--background-color);
}

body div.public-layout {
  background: #eee;
}

h1.column-header {
  color: $white;
}
