@import 'mastodon-light/variables';
@import 'original';
@import 'mastodon-light/diff';
@import 'framapiaf/fixes';

h1.column-header {
  color: $primary-text-color;
}

article,
.status__wrapper {
  background: $white;
}
